import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import { Switch } from 'react-router';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './App.scss';
import { RootState } from './models/RootState';

import AcceptTerms from './pages/AcceptTerms/AcceptTerms';
import { fetchMailVerifiedSuccess, logout } from './store';
import ResetPassword from './pages/ResetPasswordPage/ResetPasswordPage';
import AccessRedirect from './pages/AccessRedirect/Accessredirect';
import { setTimeout } from 'timers';
import LoadingPage from './pages/LoadingPage/LoadingPage';
import LoginPage from './pages/Login/LoginPage';
import SignUp from './pages/SignUp/SignUp';
import { fetchContentConfig, getDomainConfig } from './store/common/commonActions';
const MainPage = lazy(() => import('./router/UserRouter'));

type StateProps = {
  fetchMailVerifiedSuccess: (mailVerified: boolean) => void;
  logout: () => void;
  mailVerified: boolean;
  prefersDark: boolean;
  loggedIn: boolean;
  fetchContentConfig: any;
  getDomainConfig: any;
};

const App: React.FC<StateProps> = (props) => {
  const consoleOpen = useConsoleOpen();
  // const consoleOpen = false;

  const { logout, prefersDark, loggedIn, fetchContentConfig, getDomainConfig } = props;

  useEffect(() => {
    const toggleTheme = (systemInitiatedDark) => {
      if (systemInitiatedDark?.matches) {
        document.body.setAttribute('data-theme', 'dark');
      } else {
        document.body.setAttribute('data-theme', 'light');
      }
    };

    let systemDark = window.matchMedia('(prefers-color-scheme: dark)');
    systemDark.addListener((e) => toggleTheme(prefersDark));
    document.body.classList.toggle('dark', prefersDark);
  }, [prefersDark]);

  const handleClose = () => {
    console.log('user open dev tools');
  };

  useEffect(() => {
    fetchContentConfig();
  }, []);

  return (
    <>
      {!consoleOpen ? (
        <Suspense fallback={<LoadingPage />}>
          <BrowserRouter>
            <Switch>
              <Route path="/access-redirect" component={AccessRedirect} />
              <Route path="/terms-and-conditions" component={AcceptTerms} />
              <Route path="/reset-password" component={ResetPassword} />
              <Route path="/register" component={SignUp} />
              <Route path="/login" component={LoginPage} />
              <Route path="/register" component={SignUp} />
              <Route path="/" component={MainPage} />
            </Switch>
          </BrowserRouter>
        </Suspense>
      ) : null}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Dialog
        open={consoleOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullWidth={true}
        className="dev-tools-msg-modal"
      >
        <DialogContent className="modal-content-ctn">
          <div className="dev-tools-warning-msg">
            {' '}
            Due to some inactivity or security reasons stop your website, please
            close the developer tool. Thank you for your support
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export const useConsoleOpen = () => {
  const [consoleOpen, setConsoleOpen] = useState(false);

  useEffect(() => {
    let checkStatus;

    var element = new Image();
    Object.defineProperty(element, 'id', {
      get: function () {
        checkStatus = true;
        throw new Error('Dev tools checker');
      },
    });
    requestAnimationFrame(function check() {
      setTimeout(() => {
        checkStatus = false;
        // Don't delete this console statements
        // Uncomment fater fixing ios issues
        // if (process.env.REACT_APP_NODE_ENV !== 'development') {
        //   console.dir(element);
        //   console.clear();
        // }
        setConsoleOpen(checkStatus);
        requestAnimationFrame(check);
      }, 1000);
    });
  });

  return consoleOpen;
};

const mapStateToProps = (state: RootState) => {
  return {
    mailVerified: state.auth.mailVerified,
    prefersDark: state.common.prefersDark,
    loggedIn: state.auth.loggedIn,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchMailVerifiedSuccess: (mailVerified: boolean) =>
      dispatch(fetchMailVerifiedSuccess(mailVerified)),
    logout: () => dispatch(logout()),
    fetchContentConfig: () => dispatch(fetchContentConfig()),
    getDomainConfig: () => dispatch(getDomainConfig())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
