import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IonSpinner } from '@ionic/react';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import langData from '../../language-packs/language-codes.json';

import './LoginForm.scss';
import {
  fetchBalance,
  loginSuccess,
  loginFailed,
  requestStart,
  requestEnd,
} from '../../store';
import { RootState } from '../../models/RootState';
import { IonLabel } from '@ionic/react';
import UsernameVerfication from '../ForgotPassword/ForgotPassword';
import Modal from '../Modal/Modal';
// Password Encryptioon
import SVLS_API from '../../svls-api';
import { DomainConfig } from '../../models/DomainConfig';

type StoreProps = {
  loginSuccess: Function;
  loginFailed: Function;
  requestStart: Function;
  requestEnd: Function;
  fetchBalance: Function;
  domainConfig: DomainConfig;
};

type LoginProps = StoreProps & {
  errorMsg: string;
  loading: boolean;
  loggedIn: boolean;
  modalCloseHandler: Function;
  redirectUrl?: string;
  langSelected: string;
};

const LoginForm: React.FC<LoginProps> = (props) => {
  const {
    errorMsg,
    loading,
    loggedIn,
    loginSuccess,
    loginFailed,
    requestStart,
    requestEnd,
    fetchBalance,
    modalCloseHandler,
    redirectUrl,
    domainConfig,
    langSelected,
  } = props;

  const [showPassword, setShowPassword] = useState(false);
  const [loginResponse, setLoginResponse] = useState(null);
  const [useAuthenticator, setUseAuthenticator] = useState<boolean>(false);
  const [showForgotPwdModal, setShowForgotPwdModal] = useState(false);
  const [loadLogin, setLoadLogin] = useState(false);
  const [demoLoading, setDemoLoading] = useState(false);

  let history = useHistory();
  const { search } = useLocation();
  const authToken = new URLSearchParams(search).get('authToken');
  useEffect(() => {
    if (authToken) {
      getSapToken(authToken);
      const username = authToken?.split('.')?.[1];
      let uname = JSON.parse(window?.atob(username)).sub;
      sessionStorage.setItem('username', uname);
      sessionStorage.setItem('jwt_token', authToken);
      loginSuccess(authToken);
      history.push('/home');
      fetchBalance();
    }
  }, [authToken]);

  const formik = useFormik({
    initialValues: { username: '', password: '', code: '' },
    validationSchema: Yup.object({
      username: Yup.string().required(langData[langSelected]['900018']),
      password: Yup.string(),
      code: Yup.string(),
    }),
    onSubmit: async (values) => {
      setLoadLogin(true);
      requestStart();

      try {
        const loginRequest = {
          username: values.username.toLowerCase(),
          password: values.password.trim(),
        };
        const response = await SVLS_API.post('/account/v2/login', loginRequest);
        setLoadLogin(false);
        setLoginResponse(response.data);
        requestEnd();
        getSapToken(response.data);
        sessionStorage.setItem('username', values.username.toLowerCase());
        sessionStorage.setItem('jwt_token', response.data);

        localStorage.removeItem(
          `multiMarket_${values?.username?.toLowerCase()}`
        );

        loginSuccess(response.data);

        let claim = response.data.split('.')[1];
        let status = JSON.parse(window.atob(claim)).sts;

        sessionStorage.setItem('aid', JSON.parse(window.atob(claim)).aid);

        if (status === 2) {
          history.replace('/terms-and-conditions');
        } else if (status === 4) {
          history.replace('/reset-password');
        }

        if (redirectUrl) {
          console.log(redirectUrl);
          history.push(redirectUrl);
        }
      } catch (err) {
        loginFailed(err.response.data.message);
      } finally {
        requestEnd();
      }
    },
  });

  const handleDemoLogin = async () => {
    setDemoLoading(true);
    setLoadLogin(false);
    requestStart();
    try {
      const response = await SVLS_API.post('/account/v2/login-demo');
      setDemoLoading(false);
      if (response.status == 200) {
        setLoginResponse(response.data);
        requestEnd();

        let claim = response.data.split('.')[1];
        const username = JSON.parse(window.atob(claim)).sub;
        sessionStorage.setItem('username', username.toLowerCase());
        sessionStorage.setItem('jwt_token', response.data);
        console.log(response.data);
        localStorage.removeItem(`multiMarket_${username.toLowerCase()}`);

        loginSuccess(response.data);

        let status = JSON.parse(window.atob(claim)).sts;

        sessionStorage.setItem('aid', JSON.parse(window.atob(claim)).aid);

        if (redirectUrl) {
          history.push(redirectUrl);
        }
      }
    } catch (err) {
      setDemoLoading(false);
      console.log(err);
    }
  };

  const getSapToken = async (token: string) => {
    try {
      const response = await SVLS_API.get(
        `/account/v2/accounts/${
          JSON.parse(window.atob(token.split('.')[1])).aid
        }/sap-token`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (response.status == 200) {
        sessionStorage.setItem('sap_token', response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (loggedIn) {
      fetchBalance();
    }
  }, [fetchBalance, loggedIn]);

  useEffect(() => {
    if (loggedIn) {
      history.push('/home');
      modalCloseHandler();
    }
  }, [loggedIn, modalCloseHandler]);

  const showPasswordClickHandler = () => {
    setShowPassword(!showPassword);
  };

  const onRedirectToHome = () => {
    history.push('/home');
  };

  return (
    <div className="login-form-page">
      <form
        onSubmit={formik.handleSubmit}
        className="login-form-ctn"
        autoComplete="off"
      >
        <div className="card-title">{langData[langSelected]['900013']}</div>
        <span className="usr-input">
          <IonLabel className="input-labell">
            {langData[langSelected]['400095']} /{' '}
            {langData[langSelected]['900002']}
          </IonLabel>
          <TextField
            className="login-input-field user-name"
            type="text"
            name="username"
            variant="outlined"
            error={
              formik.touched.username && formik.errors.username ? true : false
            }
            helperText={
              formik.touched.username && formik.errors.username
                ? formik.errors.username
                : null
            }
            {...formik.getFieldProps('username')}
          />
        </span>

        <div className="pwd-input">
          <IonLabel className="input-labell">
            {langData[langSelected]['900004']}
          </IonLabel>
          <FormControl
            className="login-input-field pwd-field"
            variant="outlined"
            error={
              formik.touched.password && formik.errors.password ? true : false
            }
          >
            <OutlinedInput
              id="standard-adornment-password"
              type={showPassword ? 'text' : 'password'}
              name="password"
              {...formik.getFieldProps('password')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={showPasswordClickHandler}
                    onMouseDown={showPasswordClickHandler}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {formik.touched.password && formik.errors.password ? (
              <FormHelperText error id="my-helper-text">
                {formik.errors.password}
              </FormHelperText>
            ) : null}
          </FormControl>
        </div>

        {useAuthenticator ? (
          <span className="code-input">
            <IonLabel className="input-labell">Code</IonLabel>
            <TextField
              className="login-input-field user-name"
              type="text"
              name="username"
              variant="outlined"
              {...formik.getFieldProps('code')}
            />
          </span>
        ) : null}

        {errorMsg !== '' ? (
          <span className="login-err-msg">{errorMsg}</span>
        ) : null}

        <div className="login-btns">
          <Button
            className={
              domainConfig.demoUser
                ? 'login-form-btn'
                : 'login-form-btn-without-demologin'
            }
            color="primary"
            endIcon={
              loading && loadLogin ? <IonSpinner name="lines-small" /> : ''
            }
            type="submit"
            variant="contained"
          >
            {langData[langSelected]['900006']}
          </Button>
          {domainConfig.demoUser ? (
            <Button
              className="login-form-btn"
              color="primary"
              endIcon={demoLoading ? <IonSpinner name="lines-small" /> : ''}
              onClick={handleDemoLogin}
              variant="contained"
            >
              {langData[langSelected]['900007']}
            </Button>
          ) : null}
        </div>

        <div className="bottom-link" onClick={onRedirectToHome}>
          {/* <ArrowBackIcon />  */}
          {langData[langSelected]['900014']}
        </div>
        {domainConfig.signup? (
          <>
            <div
              className="forgot-pwd-link"
              onClick={() => setShowForgotPwdModal(true)}
            >
              {langData[langSelected]['900020']}
              {' ?'}
            </div>
            <div
              className="forgot-pwd-link"
              onClick={() => history.push("/register")}
            >
              {langData[langSelected]['900021']}
            
            </div>
          </>
        ) : null}
        <Modal
          open={showForgotPwdModal}
          closeHandler={() => setShowForgotPwdModal(false)}
          title="Reset Password"
          size="xs"
        >
          <UsernameVerfication />
        </Modal>
      </form>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    loading: state.auth.loading,
    loggedIn: state.auth.loggedIn,
    errorMsg: state.auth.loginError,
    domainConfig: state.common.domainConfig,
    langSelected: state.common.selectedLang,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchBalance: () => dispatch(fetchBalance()),
    loginSuccess: (payload) => dispatch(loginSuccess(payload)),
    loginFailed: (err: string) => dispatch(loginFailed(err)),
    requestStart: () => dispatch(requestStart()),
    requestEnd: () => dispatch(requestEnd()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
